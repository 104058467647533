<template>
  <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg"
       @click="$emit('click')">
    <path clip-rule="evenodd"
          d="M6 1.2C4.81256 1.2 3.72113 1.63338 2.88281 2.35C2.84079 2.38345 2.80594 2.42502 2.78033 2.47223C2.75472 2.51944 2.73888 2.57133 2.73375 2.62479C2.72863 2.67825 2.73432 2.7322 2.75049 2.78342C2.76666 2.83464 2.79298 2.88207 2.82788 2.9229C2.86277 2.96373 2.90554 2.99711 2.95361 3.02105C3.00169 3.045 3.05409 3.05902 3.1077 3.06228C3.16131 3.06554 3.21503 3.05797 3.26565 3.04002C3.31627 3.02207 3.36276 2.99411 3.40234 2.95781C4.10163 2.36004 5.00664 2 6 2C8.07862 2 9.7804 3.57517 9.98047 5.6H8.8L10.4 8L12 5.6H10.7797C10.5752 3.14066 8.51114 1.2 6 1.2ZM1.6 4L0 6.4H1.22031C1.42483 8.85934 3.48886 10.8 6 10.8C7.18744 10.8 8.27887 10.3666 9.11719 9.65C9.15921 9.61655 9.19406 9.57498 9.21967 9.52777C9.24528 9.48056 9.26112 9.42867 9.26625 9.37521C9.27137 9.32175 9.26568 9.2678 9.24951 9.21658C9.23334 9.16536 9.20702 9.11793 9.17212 9.0771C9.13723 9.03627 9.09447 9.00289 9.04639 8.97895C8.99832 8.955 8.94591 8.94098 8.8923 8.93772C8.83869 8.93446 8.78497 8.94203 8.73435 8.95998C8.68373 8.97793 8.63724 9.00589 8.59766 9.04219C7.89837 9.63996 6.99336 10 6 10C3.92138 10 2.2196 8.42483 2.01953 6.4H3.2L1.6 4Z"
          fill="#C4D0DA"
          fill-rule="evenodd"/>
  </svg>
</template>

<script>
export default {
  name: "IconRefresh"
}
</script>

<style lang="scss" scoped>
svg {
  z-index: 10;
  cursor: pointer;
  transform: scale(0.8);
  transition: all 0s;

  &:hover {
    path {
      fill: black;
    }
  }
}
</style>
